import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import * as styles from "./blog.module.css"

const BlogOverviewPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.site.siteMetadata.blog.title}
      description={data.site.siteMetadata.blog.description}
    ></SEO>
    <h1>{data.site.siteMetadata.blog.title}</h1>
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <div key={node.id} className={styles.article}>
        <Link className={styles.link} to={node.fields.slug}>
          <div className={styles.titleBar}>
            <div className={styles.title}>{node.frontmatter.title}</div>
            <div className={styles.date}>{node.frontmatter.date}</div>
          </div>
          <p>{node.excerpt}</p>
          <div>
            <FontAwesomeIcon
              className={styles.clock}
              icon={faClock}
            ></FontAwesomeIcon>
            {node.timeToRead} min reading time
          </div>
        </Link>
      </div>
    ))}
  </Layout>
)

export default BlogOverviewPage

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { type: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
          }
          fields {
            slug
          }
          timeToRead
          excerpt
        }
      }
    }
    site {
      siteMetadata {
        blog {
          title
          description
        }
      }
    }
  }
`
